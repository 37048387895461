import React, { useState, useEffect, useRef } from 'react'
import './popchat.css'
import ForumIcon from '@mui/icons-material/Forum'
import SendIcon from '@mui/icons-material/Send'
import CloseIcon from '@mui/icons-material/Close'
import { v4 as uuidv4 } from 'uuid'

export const PopChat = (props) => {
  const [chatOpen, setChatOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [chatId, setChatId] = useState('')
  const [isTyping, setIsTyping] = useState(false)
  const { messages, setMessages } = props
  const messagesEndRef = useRef(null)
  const chatBoxRef = useRef(null)

  useEffect(() => {
    setChatId(uuidv4())
  }, [])

  useEffect(() => {
    scrollToBottom()
  }, [messages])

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  const toggleChat = () => {
    setChatOpen(!chatOpen)
  }

  const formatTimestamp = () => {
    const now = new Date()
    return now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
  }

  const handleSend = async () => {
    if (inputValue.trim()) {
      const userMessage = {
        text: inputValue,
        timestamp: formatTimestamp(),
        type: 'user'
      }
      
      setMessages(prevMessages => [...prevMessages, userMessage])
      setInputValue('')
      setIsTyping(true)

      const payload = {
        chat_id: chatId,
        user_query: inputValue
      }

      try {
        console.log('Sending payload:', payload)
        const response = await fetch('https://d38hw9x674y6i3.cloudfront.net/chat', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        })

        const data = await response.json()
        console.log('Response data:', data)
        setIsTyping(false)

        if (data && data.response) {
          const botMessage = {
            text: data.response,
            timestamp: formatTimestamp(),
            type: 'bot'
          }
          setMessages(prevMessages => [...prevMessages, botMessage])
        } else {
          const errorMessage = {
            text: "Error: No response from server",
            timestamp: formatTimestamp(),
            type: 'bot'
          }
          setMessages(prevMessages => [...prevMessages, errorMessage])
        }
      } catch (error) {
        console.error('Error sending message:', error)
        setIsTyping(false)
        const errorMessage = {
          text: "Error: Unable to send message",
          timestamp: formatTimestamp(),
          type: 'bot'
        }
        setMessages(prevMessages => [...prevMessages, errorMessage])
      }
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      handleSend()
    }
  }

  // Function to format messages with markdown-style formatting
  const formatMessage = (msg) => {
    if (!msg) return ''
    // Replace **text** with <strong>text</strong>
    return msg.split(/\*\*(.*?)\*\*/g).map((part, index) => 
      index % 2 === 1 ? <strong key={index}>{part}</strong> : part
    )
  }

  return (
    <div id='chatCon'>
      <div className={`chat-box ${chatOpen ? 'active' : ''}`} ref={chatBoxRef}>
        <div className="header">
          <span>Chat with us</span>
          <CloseIcon className="close-chat" onClick={toggleChat} />
        </div>
        <div className="msg-area">
          {messages.map((msg, i) => (
            <div key={i} className={`message ${msg.type === 'user' ? 'right' : 'left'}`}>
              <span>{formatMessage(msg.text)}</span>
              <div className="timestamp">{msg.timestamp}</div>
            </div>
          ))}
          {isTyping && (
            <div className="message left">
              <div className="typing-indicator">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>
        <div className="footer">
          <input 
            type="text" 
            value={inputValue} 
            onChange={(e) => setInputValue(e.target.value)} 
            onKeyDown={handleKeyPress}
            placeholder="Type a message..."
          />
          <button onClick={handleSend}>
            <SendIcon />
          </button>
        </div>
      </div>
      <div className="pop" onClick={toggleChat}>
        <ForumIcon />
      </div>
    </div>
  )
}

export default PopChat