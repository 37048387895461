const ThumbnailsMapper = {

    // Welcome
    "what_awaits_you": require("../components/assets/images/thumbnails/what_awaits_you.jpg"),
    "the_value_of_this_academy": require("../components/assets/images/thumbnails/the_value_of_this_academy.jpg"),
    "your_next_steps": require("../components/assets/images/thumbnails/your_next_steps.jpg"),

    // Real Estate Basics
    "real_estate_vs._mutual_funds": require("../components/assets/images/thumbnails/real_estate_vs_mutual_funds.jpg"),
    "saving_up_vs._paying_off": require("../components/assets/images/thumbnails/saving_up_vs_paying_off.jpg"),
    "additional_purchase_costs": require("../components/assets/images/thumbnails/additional_purchase_costs.jpg"),
    "cash_flow_basics": require("../components/assets/images/thumbnails/cash_flow_basics.jpg"),
    "capital_gains_without_value_appreciation": require("../components/assets/images/thumbnails/capital_gains_without_value_appreciation.jpg"),
    "purchase_price_factor": require("../components/assets/images/thumbnails/purchase_price_factor.jpg"),
    "rental_yield": require("../components/assets/images/thumbnails/rental_yield.jpg"),
    "personal_home_vs._investment_property": require("../components/assets/images/thumbnails/personal_home_vs_investment_property.jpg"),

    // .. define next chapters here
  };
  
export default ThumbnailsMapper;