import React from "react";
import { useNavigate, Link } from "react-router-dom";
import "./AboutUsPage.css";
import logo from "./logo.png";
import logoIcon from "../components/assets/logo.png";
import portrait_Loui from '../components/assets/images/loui.jpeg';
import portrait_Shree from '../components/assets/images/shree.jpeg';
import portrait_hari from '../components/assets/images/hari.jpeg';
import about_us_2 from '../components/assets/images/about-us-2.jpg';
import about_us_3 from '../components/assets/images/about-us-3.jpg';
import about_us_4 from '../components/assets/images/about-us-4.jpg';
import about_us_5 from '../components/assets/images/about-us-5.jpg';
import { handleDashboardNavigation } from "../App";


function AboutUsPage() {
  const navigate = useNavigate();

  const handleStartForFree = () => {
    navigate('/login?signup=true');
  };

  return (
    <div className="about-us-page">
      <header>
        <img
          src={logo}
          alt="Logo"
          className="logo"
          onClick={() => navigate("/")}
        />
        <nav className="nav-links">
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              navigate("/");
            }}
          >
            Home
          </a>
          <a
            href="/faq"
            onClick={(e) => {
              e.preventDefault();
              navigate('/#faq');
            }}
          >
            FAQ
          </a>
          <a
            href="/contact"
            onClick={(e) => {
              e.preventDefault();
              navigate("/contact");
            }}
          >
            Contact Us
          </a>
          <a
            href="/about-us"
            onClick={(e) => {
            e.preventDefault();
              navigate("/about-us");
            }}
          >
            About Us
          </a>
          <a
            href="/dashboard"
            onClick={(e) => {
            e.preventDefault();
            handleDashboardNavigation(navigate);
            }}
          >
            Dashboard
          </a>
        </nav>
        <button className="sign-up-button" onClick={handleStartForFree}>
          Sign up →
        </button>
      </header>

      <main>
        <div className="about-content">
          <section className="welcome-section">
            <div className="welcome-container">
              <div className="section-content-welcome">
                <h1>Welcome to Concrete Gold Academy</h1>
                <div className="welcome-text">
                  <p>
                    Unlock the secrets of real estate success while putting more
                    money back into your pocket. At Concrete Gold Academy, we don't
                    just teach real estate—we empower you to save, grow, and thrive.
                    For instance, learn how to save €321.50 in taxes every month
                    while building a lucrative portfolio and mastering the skills
                    needed to navigate the complex real estate landscape.
                  </p>
                  <p>
                    Our mission is simple: to give you the tools, insights, and
                    confidence to achieve financial freedom through real estate.
                    Whether you're looking to invest smartly, manage properties
                    effectively, or explore new market opportunities, we're here to
                    guide you every step of the way.
                  </p>
                </div>
              </div>
              <div className="welcome-logo">
                <img src={logoIcon} alt="CGA Lion Logo" />
              </div>
            </div>
          </section>

          <section className="vision-section">
            <div className="vision-title">
              <h1>Redefining Real Estate Education</h1>
              <p>Your gateway to mastering real estate investment, management, and consulting with practical knowledge and AI-powered tools.</p>
            </div>
            <div className="vision-container">
              <div className="vision-image">
                <img src={about_us_2} alt="Real Estate Education" />
              </div>
              <div className="section-content-vision">
                <h2>Our Story</h2>
                <p>
                  Concrete Gold Academy was founded with a vision to bridge the
                  gap between traditional real estate education and modern
                  industry demands. Our founders, a passionate team of real estate
                  experts, tech innovators, and educators, recognized the need for
                  accessible, practical, and future-focused learning.
                </p>
              </div>
            </div>
          </section>

          <section className="values-section">
            <div className="values-title">
              <h1>What Sets Us Apart</h1>
            </div>
            <div className="values-container">
              <div className="features-list">
                <div className="feature-item">
                  <span className="arrow">→</span>
                  <div className="feature-text">
                    <h2>Maximize Tax Benefits</h2>
                    <p>Learn strategies to reduce your tax burden</p>
                  </div>
                </div>
                <div className="feature-item">
                  <span className="arrow">→</span>
                  <div className="feature-text">
                    <h2>Real-World Learning</h2>
                    <p>Hands-on projects tailored to real estate challenges</p>
                  </div>
                </div>
                <div className="feature-item">
                  <span className="arrow">→</span>
                  <div className="feature-text">
                    <h2>Flexible & Accessible</h2>
                    <p>Learn anytime, anywhere, at your own pace</p>
                  </div>
                </div>
                <div className="feature-item">
                  <span className="arrow">→</span>
                  <div className="feature-text">
                    <h2>Expert Guidance</h2>
                    <p>Direct mentorship from seasoned professionals</p>
                  </div>
                </div>
                <div className="feature-item">
                  <span className="arrow">→</span>
                  <div className="feature-text">
                    <h2>Cutting-Edge Tools</h2>
                    <p>Use AI-driven insights for smarter decisions</p>
                  </div>
                </div>
              </div>
              <div className="values-image">
                <img src={about_us_3} alt="What Sets Us Apart" />
              </div>
            </div>
          </section>

          <section className="what-we-offer-section">
            <div className="what-we-offer-title">
              <h1>What We Offer</h1>
            </div>
            <div className="what-we-offer-container">
              <div className="what-we-offer-content">
                <div className="feature-item">
                  <span className="arrow">→</span>
                  <div className="feature-text">
                    <h2>Tailored Learning Paths</h2>
                    <p>Interactive modules covering investing, property management, and consulting.</p>
                  </div>
                </div>
                <div className="feature-item">
                  <span className="arrow">→</span>
                  <div className="feature-text">
                    <h2>Flexible Learning</h2>
                    <p>Flexible learning schedules to accommodate professionals.</p>
                  </div>
                </div>
                <div className="feature-item">
                  <span className="arrow">→</span>
                  <div className="feature-text">
                    <h2>Hands-On Projects</h2>
                    <p>Real-world tasks to apply theoretical knowledge.</p>
                  </div>
                </div>
                <div className="feature-item">
                  <span className="arrow">→</span>
                  <div className="feature-text">
                    <h2>Industry Challenges</h2>
                    <p>Projects designed to mimic industry challenges.</p>
                  </div>
                </div>
                <div className="feature-item">
                  <span className="arrow">→</span>
                  <div className="feature-text">
                    <h2>Ongoing Support</h2>
                    <p>Personalized feedback from mentors.</p>
                  </div>
                </div>
                <div className="feature-item">
                  <span className="arrow">→</span>
                  <div className="feature-text">
                    <h2>Community</h2>
                    <p>Networking opportunities within a vibrant community.</p>
                  </div>
                </div>
                <div className="feature-item">
                  <span className="arrow">→</span>
                  <div className="feature-text">
                    <h2>AI-Driven Tools</h2>
                    <p>Market analysis and property insights using advanced technology.</p>
                  </div>
                </div>
              </div>
              <div className="what-we-offer-image">
                <img src={about_us_4} alt="What We Offer" />
              </div>
            </div>
          </section>

          <section className="meet-team">
            <div className="meet-team-title">
              <h1>Meet Our Team</h1>
              <p>Our team includes experienced professionals and innovators, all working toward one mission—your success.</p>
            </div>
            <div className="meet-team-container">
              <div className="section-content-team">
                <p>
                  At Concrete Gold Academy, we've assembled a diverse group of experts 
                  who bring together their unique perspectives and extensive experience 
                  in real estate, technology, and education. Each team member contributes 
                  their specialized knowledge to create a comprehensive learning experience 
                  that addresses every aspect of real estate investment and management.
                </p>
                <p>
                  From seasoned real estate consultants to innovative tech developers 
                  and creative strategists, our team works in perfect synergy to deliver 
                  cutting-edge solutions and practical insights that help our students 
                  succeed in the dynamic world of real estate.
                </p>
              </div>
            </div>
          </section>

          <section className="team-section">
            <div className="team-member-portrait">
              <img src={portrait_Loui} alt="Loui Sera" />
            </div>
            <div className="team-member-detail">
              <h2>LOUI SERA</h2>
              <div className="title">Founder & Real Estate Consultant</div>
              <p>
                Hi, my name is Loui and I founded the Concrete Gold Academy
                because I had a problem. My calendar used to be overloaded with
                so many consultations and phone calls with expats who came to me
                for 1 common reason: They were unhappy about their incredibly
                high tax burden and were looking for tax-beneficial investments.
                I reached the point at which I could not help all of them
                anymore and decided to filter. From now on, I only advised those
                10% who assured me that their problem of high tax burdens was so
                bad that they were urgently looking for a solution. However, due
                to time constraints, I could not help the other 90% anymore who
                were looking for an option to build their knowledge in this area
                first. That sucked. Because I actually follow the principle that
                you should only invest in what you understand. And that requires
                knowledge.
              </p>
              <p>
                When I read that book by Alex Fischer (a successful German real estate entrepreneur), I realized that I had to build a machine that would allow me to multiply my knowledge and make it accessible to everyone. And of course, the best way to do this is to record videos that contain everything a beginner needs to know to invest in real estate successfully and save massive amounts of taxes. And you found your way here today, that's great. Are you also unhappy about your incredibly high tax burden and want to turn taxes into assets? Become part of the Concrete Gold Academy and we will show you the solution. I would love to see you around!
              </p>
            </div>
          </section>

          <section className="team-section">
            <div className="team-member-portrait">
              <img src={portrait_Shree} alt="Shreekara Kulkarni" />
            </div>
            <div className="team-member-detail">
              <h2>SHREEKARA KULKARNI</h2>
              <div className="title">Creative Director & Product Strategist</div>
              <p>
                Hi, I'm Shreekie—a creative thinker with a passion for blending
                design, education, and business. My journey started in Interior
                Architecture and Design, where I discovered the art of creating
                spaces that truly connect with people. Over time, my curiosity
                led me to explore the world of EdTech and business strategy,
                where I realized I could use my creativity to build solutions
                that make a real difference.
              </p>
              <p>
                At the Concrete Gold Academy, I combine all these experiences. I
                shape products that help people navigate the real estate world
                with confidence while driving the strategies that keep our
                academy growing and evolving. My role is all about finding the
                balance between creativity and practicality, and I love the
                challenge of turning ideas into something impactful.
              </p>
              <p>
                For me, it's always been about empowering others—whether it's
                through a beautifully designed space, a smart strategy, or a
                learning experience that opens new doors. I'm excited to keep
                building, learning, and creating along the way.
              </p>
            </div>
          </section>

          <section className="team-section">
            <div className="team-member-portrait">
              <img src={portrait_hari} alt="Harikrishnan Venugopal" />
            </div>
            <div className="team-member-detail">
              <h2>HARIKRISHNAN VENUGOPAL</h2>
              <div className="title">Lead Developer</div>
              <p>
                Hi, I'm Hari, a tech enthusiast with a passion for building reactive applications and data science. With my background in Electrical engineering, I gained the ability to manage projects. I truly kickstarted my tech career when I moved to New Zealand to study Master of Information Technology degree from The University of Waikato. I joined Concrete Gold Academy to build a platform that will help people to learn real estate investment, management and consulting with practical knowledge and AI-powered tools.
              </p>
              <p>
                From my behind the scenes knowledge I can confidently say one thing, big things are being developed at Concrete Gold Academy. This platform is truly going to leverage the power of AI and data science to truly create a transformative learning experience for our users. By integrating advanced analytics and machine learning, we aim to provide personalized learning paths, predictive insights, and real-time feedback to help our users achieve their real estate goals more efficiently and effectively. I am excited to be part of this journey and to see where it takes us.
              </p>
            </div>
          </section>
          
        </div>
      </main>
      <footer className="footer">
        <div className="footer-content">
          <div className="footer-left">
            <div className="footer-logo">
              <img src={logo} alt="CGA Logo" />
              <span>CONCRETE GOLD ACADEMY</span>
            </div>
            <div className="newsletter-signup">
              <p>Sign up for the CGA newsletter</p>
              <div className="email-input-container">
                <input type="email" placeholder="Enter your email" />
                <button className="submit-arrow">→</button>
              </div>
            </div>
          </div>
          <div className="footer-links">
            <div className="footer-section">
              <h4>Company</h4>
              <Link to="/">Home</Link>
              <Link to="/about-us">About</Link>
              <Link to="/dashboard">Courses</Link>
            </div>
            <div className="footer-section">
              <h4>Documentation</h4>
              <Link to="/contact">Contact</Link>
              <Link to="/#faq">FAQ</Link>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </div>
            <div className="footer-section">
              <h4>Social</h4>
              <a href="https://www.linkedin.com/company/concrete-gold-academy/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
              <a href="https://www.instagram.com/concrete_gold_academy/" target="_blank" rel="noopener noreferrer">Instagram</a>
              <a href="https://www.youtube.com/@seraloui" target="_blank" rel="noopener noreferrer">Youtube</a>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <p>© CGA All Rights Reserved 2024</p>
          <Link to="/terms-of-service">Terms & Conditions</Link>
        </div>
      </footer>
    </div>
  );
}

export default AboutUsPage;
