import React, { useState, useEffect } from 'react';
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeSanitize from "rehype-sanitize";
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { FaSignOutAlt, FaHome } from 'react-icons/fa';
import './VideoDetailPage.css';
import logo from './logo.png';
import ThumbnailsMapper from '../mapper/ThumbnailsMapper';
import { handleDashboardNavigation } from '../App';

const formatTitleForThumbnail = (title) => title.toLowerCase().replace(/\s+/g, "_");

function VideoDetailPage() {
  const { videoId } = useParams(null);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [videoGroups, setVideoGroups] = useState([]);
  const [isGroupsAlreadyFetched, setGroupsAlreadyFetched] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchVideoGroups = async () => {
      if (isGroupsAlreadyFetched) return; // Prevent re-fetching

      const accessToken = localStorage.getItem('access_token');
      try {
        const response = await fetch('https://kg7x2egnud.execute-api.eu-central-1.amazonaws.com/videos/all-groups', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          },
        });

        if (response.status === 401) {
          localStorage.removeItem('access_token');
          localStorage.removeItem('refresh_token');
          localStorage.removeItem('username');
          navigate('/login');
          return;
        }

        if (response.ok) {
          const data = await response.json();
          setVideoGroups(data.video_groups);
          setGroupsAlreadyFetched(true);
        }
        
      } catch (error) {
        console.error('Error fetching video groups:', error);
      }
    };

    fetchVideoGroups();
  }, [isGroupsAlreadyFetched]);

  useEffect(() => {
    if (!videoId) return;

    const fetchSignedUrl = async () => {
      const accessToken = localStorage.getItem('access_token');
      try {
        const urlResponse = await fetch(`https://kg7x2egnud.execute-api.eu-central-1.amazonaws.com/videos/generate-signed-url?video_id=${videoId}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          },
        });

        if (urlResponse.ok) {
          const urlData = await urlResponse.json();
          const foundGroup = videoGroups.find(group => group.videos.some(video => video.video_id === videoId));
          const foundVideo = foundGroup?.videos.find(video => video.video_id === videoId);
          if (foundVideo) {
            setCurrentVideo({ ...foundVideo, video_url: urlData.video_url, thumbnail_url: urlData.thumbnail_url });
          }
        }
      } catch (error) {
        console.error('Error fetching signed URL:', error);
      }
    };

    fetchSignedUrl();

  }, [videoId, videoGroups]);

  const handleRelatedVideoClick = (videoId) => {
    navigate(`/video/${videoId}`);
  };

  const handleLogout = async () => {
    const accessToken = localStorage.getItem('access_token');
  
    try {
      await fetch('https://kg7x2egnud.execute-api.eu-central-1.amazonaws.com/auth/logout', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      });
    } catch (error) {
      console.error('Logout request failed:', error);
    }
  
    // Always clear local cache and navigate to login, regardless of API response
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('username');
    localStorage.removeItem('password');
    
    navigate('/login');
  };

  return (
    <div className="video-detail-container">
      <header className="top-bar">
        <img src={logo} alt="Logo" className="left-logo" onClick={() => navigate('/')} />
        <nav className="nav-links">
          <Link to="/">Home</Link>
          <Link to="/about-us">About Us</Link>
          <a href="#faq" onClick={(e) => {
            e.preventDefault();
            navigate('/#faq');
          }}>FAQ</a>
          <a href="/dashboard" onClick={(e) => {
            e.preventDefault();
            handleDashboardNavigation(navigate);
          }}>Dashboard</a>
          <Link to="/contact">Contact Us</Link>
        </nav>
        <Button
          className="logout-button"
          variant="contained"
          color="error"
          onClick={handleLogout}
          startIcon={<FaSignOutAlt />}
        >
          Logout
        </Button>
      </header>

      <div className="content">
        <div className="main-content">
          <div className="video-section">
            {currentVideo && (
              <video
                controls
                className="video-player"
                key={currentVideo.video_url}
              >
                <source src={currentVideo.video_url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
          </div>

          <div className="video-details">
            {currentVideo && (
              <>
                <h2 className="video-title">{currentVideo.title}</h2>
                <hr className="video-title-divider" />
                <div className="video-description">
                  {currentVideo.description.split("\n").map((line, index) => (
                    <ReactMarkdown
                      key={index}
                      remarkPlugins={[remarkGfm]}
                      rehypePlugins={[rehypeSanitize]}
                    >
                      {line}
                    </ReactMarkdown>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>

        <div className="vertical-video-list">
          {videoGroups.map(group => (
            <div className="related-videos" key={group.id}>
              <h3 className="module-title">{group.title}</h3>
              <div className="video-list">
                {group.videos.map((video) => {
                  const formattedTitle = formatTitleForThumbnail(video.title);
                  const imageSrc = ThumbnailsMapper[formattedTitle] || require("../components/assets/images/thumbnails/default_thumbnail.png");

                  return (
                    <div
                      key={video.video_id}
                      className={`related-video-item ${video.video_id === videoId ? 'active' : ''}`}
                      onClick={() => handleRelatedVideoClick(video.video_id)}
                    >
                      <div className="video-thumbnail">
                        <img src={imageSrc} alt={video.title} />
                      </div>
                      <div className="video-info">
                        <h4>{video.title || 'Untitled Video'}</h4>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default VideoDetailPage;
