import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './ContactUsPage.css';
import logo from '../components/assets/logo.png';
import { handleDashboardNavigation } from '../App';

const ContactUsPage = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://e5p7ihg0ch.execute-api.eu-central-1.amazonaws.com/contact-us-trigger', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'OPTIONS, POST',
          'Access-Control-Allow-Headers': 'Content-Type'
        },
        body: JSON.stringify(formData)
      });

      if (!response.ok) {
        throw new Error('Failed to send message');
      }

      setFormData({
        name: '',
        email: '',
        subject: '',
        message: ''
      });
      alert('Thank you for your message. We will get back to you soon!');
    } catch (error) {
      console.error('Error sending message:', error);
      alert('Sorry, there was an error sending your message. Please try again later.');
    }
  };

  return (
    <div className="contact-us-page">
      <header>
        <img
          src={logo}
          alt="Logo"
          className="logo"
          onClick={() => navigate("/")}
        />
        <nav className="nav-links">
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              navigate("/");
            }}
          >
            Home
          </a>
          <a
            href="/faq"
            onClick={(e) => {
              e.preventDefault();
              navigate('/#faq');
            }}
          >
            FAQ
          </a>
          <a
            href="/contact"
            onClick={(e) => {
              e.preventDefault();
              navigate("/contact");
            }}
          >
            Contact Us
          </a>
          <a
            href="/about-us"
            onClick={(e) => {
            e.preventDefault();
              navigate("/about-us");
            }}
          >
            About Us
          </a>
          <a
            href="/dashboard"
            onClick={(e) => {
            e.preventDefault();
            handleDashboardNavigation(navigate);
            }}
          >
            Dashboard
          </a>
        </nav>
      </header>

      <main>
        <div className="contact-container">
          <div className="section-content">
            <h2>Contact Us</h2>
            <p>Have questions? We'd love to hear from you. Send us a message and we'll respond as soon as possible.</p>
            
            <form onSubmit={handleSubmit} className="contact-form">
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  placeholder="Your name"
                />
              </div>

              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  placeholder="your.email@example.com"
                />
              </div>

              <div className="form-group">
                <label htmlFor="subject">Subject</label>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  required
                  placeholder="What is this regarding?"
                />
              </div>

              <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  placeholder="Your message here..."
                  rows="5"
                />
              </div>

              <button type="submit" className="submit-button">Send Message</button>
            </form>

            <div className="contact-info">
              <h2>Other Ways to Reach Us</h2>
              <div className="info-items">
                <div className="info-item">
                  <h3>Email</h3>
                  <p>contact@cg-academy.online</p>
                </div>
                <div className="info-item">
                  <h3>Phone</h3>
                  <p>+49 151 12019526</p>
                </div>
                <div className="info-item">
                  <h3>LinkedIn</h3>
                  <p><a href="https://linkedin.com/company/concrete-gold-academy" target="_blank" rel="noopener noreferrer">Concrete Gold Academy</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <footer className="footer">
        <div className="footer-content">
          <div className="footer-left">
            <div className="footer-logo">
              <img src={logo} alt="CGA Logo" />
              <span>CONCRETE GOLD ACADEMY</span>
            </div>
            <div className="newsletter-signup">
              <p>Sign up for the CGA newsletter</p>
              <div className="email-input-container">
                <input type="email" placeholder="Enter your email" />
                <button className="submit-arrow">→</button>
              </div>
            </div>
          </div>
          <div className="footer-links">
            <div className="footer-section">
              <h4>Company</h4>
              <Link to="/">Home</Link>
              <Link to="/about-us">About</Link>
              <Link to="/dashboard">Courses</Link>
            </div>
            <div className="footer-section">
              <h4>Documentation</h4>
              <Link to="/contact">Contact</Link>
              <Link to="/#faq">FAQ</Link>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </div>
            <div className="footer-section">
              <h4>Social</h4>
              <a href="https://www.linkedin.com/company/concrete-gold-academy/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
              <a href="https://www.instagram.com/concrete_gold_academy/" target="_blank" rel="noopener noreferrer">Instagram</a>
              <a href="https://www.youtube.com/@seraloui" target="_blank" rel="noopener noreferrer">Youtube</a>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <p>© CGA All Rights Reserved 2024</p>
          <Link to="/terms-of-service">Terms & Conditions</Link>
        </div>
      </footer>
    </div>
  );
};

export default ContactUsPage; 